<!--  -->
<template>
  <div class="content">
    <div class="inner">
      <div class="box layout-flex-all">
        <div class="left">
          <div class="logo" @click="$router.push('/')">
            <img src="@/assets/img/login_logo.png" />
          </div>
          <div class="name">
            欢迎进入
            <br />API登录系统
          </div>
          <p class="tips">数据开放·技术共享</p>
          <div class="copyRight">潍坊华创信息技术有限公司</div>
        </div>
        <div class="right">
          <div class="tab">
            <span>账号密码登录</span>
          </div>
          <div class="form">
            <el-form :model="loginForm" status-icon ref="loginForm" class="demo-ruleForm">
              <el-form-item
                prop="phone"
                :rules="[{ required: true, message: '请输入登录手机号', trigger: 'blur' }]"
              >
                <el-input
                  v-model="loginForm.phone"
                  autocomplete="off"
                  prefix-icon="iconfont pdxshouji"
                  placeholder="请输入登录手机号"
                  :clearable="false"
                  @keyup.enter.native="handleNameChange('loginForm')"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="code"
                :rules="[{ required: true, message: '请输入验证码', trigger: 'blur' }]"
              >
                <el-input
                  type="password"
                  v-model="loginForm.code"
                  autocomplete="off"
                  prefix-icon=" iconfont pdxicon2"
                  placeholder="请输入验证码"
                  show-password
                  :clearable="false"
                  @keyup.enter.native="handleNameChange('loginForm')"
                ></el-input>
                <span class="code">获取验证码</span>
              </el-form-item>
              <div class="other layout-flex">
                <img src="@/assets/img/login_wx.png" />微信扫码登录
              </div>
              <el-form-item>
                <!-- @click="toGetCaptcha('loginForm')" -->
                <el-button style="width: 100%" @click="mockLogin">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loginForm: {
        phone: "",
        code: "",
      },
    };
  },

  components: {},

  computed: {},

  mounted: {},

  methods: {
    mockLogin () {
      this.$store.commit("saveToken", "data")
      window.location = "http://192.168.0.115:8080/#/?url=192.168.0.115&port=9001"
      // this.$router.push('console/dashboard')
    }
  }
}

</script>
<style lang='scss' scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
  .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../../assets/img/login_bg.png);
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .box {
      align-items: flex-start;
      padding: 90px 60px 20px;
      margin: 0 auto;
      max-width: 860px;
      width: 110%;
      height: 585px;
      position: absolute;
      top: 15%;
      // bottom: 0;
      right: 50%;
      transform: translateX(50%);
      // left: 0;
      background: url(../../assets/img/login_inner_bg.png) no-repeat 100% 0 /
        100% 100%;
      box-sizing: border-box;
      .left {
        position: relative;
        width: 240px;
        height: 100%;
        .logo {
          margin-bottom: 40px;
          cursor: pointer;
        }
        .name {
          margin-bottom: 20px;
          color: #fff;
          font-size: 38px;
          line-height: 1.4;
        }
        .tips {
          color: #fff;
        }
        .copyRight {
          font-size: 14px;
          color: #fff;
          position: absolute;
          bottom: 0;
        }
      }
      .right {
        width: 390px;
        .tab {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #e8e8e8;
          span {
            padding: 0 10px;
            font-size: 20px;
            color: #1890ff;
            position: relative;
            &::after {
              content: '';
              width: 100%;
              height: 4px;
              display: block;
              position: absolute;
              bottom: -20px;
              left: 0;
              background: #1890ff;
            }
          }
        }
        .form ::v-deep .el-form-item {
          margin-bottom: 30px;
        }
        .form ::v-deep .el-input .el-input__inner {
          border: 1px solid #d9d9d9;
          background: #fff;
          border-radius: 4px;
        }
        .form ::v-deep .el-button {
          color: #fff;
          background: #34acff;
          border: none;
        }
        .form ::v-deep .el-button:hover,
        .form ::v-deep .el-button:focus {
          color: #fff !important;
          background: #34acff !important;
          border: none !important;
          opacity: 0.8;
        }
        .form ::v-deep .el-input__prefix {
          height: 100%;
          width: 25px;
          text-align: center;
        }
        .form ::v-deep .el-form-item .el-form-item__content {
          display: flex;
          .code {
            margin-left: 10px;
            text-align: center;
            width: 140px;
            color: #fff;
            background: $themeTwo;
            border-radius: 4px;
            cursor: pointer;
          }
        }
        .other {
          margin-bottom: 40px;
          color: #999;
          font-size: 14px;
          cursor: pointer;
          img {
            width: 24px;
            height: 24px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
</style>